import FormGroup from "@/components/FormGroup.vue";
import FormDynamic from "@/components/FormDynamic.vue";
import Container from "@/components/Container.vue";
import Service from "@/services/base.service";
import FormAction from "@/actions/base.form";
import Template from "@/scripts/kta/submission-form-template";

export default {
  mixins: [Template, FormAction],
  components: {
    FormDynamic,
    FormGroup,
    FormAction,
    Container,
  },
  computed: {
  },
  methods: {
    async fetchPerson(filters = null) {
      try {
        const BaseService = new Service('person');
        const { data } = await BaseService.getData(filters);
        this.replaceValues(this.biodata, data);
        this.replaceValues(this.biodata, data.card_applications);
        this.biodata[0]['9'].find(item => item.name == 'matra').value = data.dimension.name;
        this.biodata[0]['9'].find(item => item.name == 'pangkat_korps').value = data.rank.name;
        this.replaceValues(this.sinyalemen, data);
        this.kehormatan = data.honors.map((item) => {
          return {
            value: item.name,
            placeholder: 'Tanda Kehormatan',
            disabled: true
          };
        });
      } catch (error) {
        console.log(error);
      }
    },
    replaceValues(template, data) {
      template.forEach((section) => {
        for (let key in section) {
          section[key].forEach((field) => {
            if (field.name in data) {
              field.value = String(data[field.name]);
            }
          });
        }
      });
      console.log(template);
    },
  },
  data() {
    return {
      cardId: '',
      cardType: 'KTA',
      nrp: '',
      listPangkat: [],
      biodata: [
        {
          '3': [
            {label: 'Ubah Foto', fileValue: {}, value: '', type: 'file', name: 'foto'},
          ],
          '9': [
            {label: 'No. KTA', value: this.cardType ? 'asdasd' : '', type: 'text', name: 'kta_number' , placeholder: 'No. KTA', disabled: true},
            {label: 'Nama Lengkap', value: '', type: 'text', name: 'nama_lengkap', placeholder: 'Nama Lengkap', disabled: true},
            {label: 'Matra',data:[], value: '', type: 'text', name: 'matra', placeholder: 'Matra', disabled: true},
            {label: 'Pangkat / Korps',data:[], value: '', type: 'text', name: 'pangkat_korps', placeholder: 'Pangkat / Korps', disabled: true},
            {label: 'Kesatuan', value: '', type: 'text', name: 'kesatuan', placeholder: 'Kesatuan', disabled: true},
          ]
        }
      ],
      sinyalemen:[
        {
          '12': [
            {label: 'Tinggi', value: '', type: 'text', tail: 'cm', name: 'tinggi', placeholder: 'Tinggi', disabled: true},
            {label: 'Berat', value: '', type: 'text', tail: 'kg', name: 'berat', placeholder: 'Berat', disabled: true},
          ],
        },
        {
          '12': [
            {label: 'Rambut', value: '', type: 'text', name: 'rambut', placeholder: 'Rambut', disabled: true},
            {label: 'Mata', value: '', type: 'text', name: 'mata', placeholder: 'Mata', disabled: true},
            {label: 'Golongan Darah', value: '', type: 'text', name: 'golongan_darah', placeholder: 'Golongan Darah', disabled: true},
          ],
        },
        {
          '12': [
            {label: 'Tempat Lahir', value: '', type: 'text', name: 'tempat_lahir', placeholder: 'Tempat Lahir', disabled: true},
            {label: 'Tanggal Lahir', value: '', type: 'date', name: 'tanggal_lahir', placeholder: 'Tanggal Lahir', disabled: true},
          ],
        },
        {
          '12': [
            {label: 'Agama', value: '', type: 'text', name: 'agama', placeholder: 'Agama', disabled: true},
          ],
        },
        {
          '12': [
            {label: 'Kontak Darurat', value: '', type: 'text', name: 'kontak_darurat', placeholder: 'Kontak Darurat', disabled: true},
          ],
        },
        {
          '12': [
            {label: 'Alamat Rumah', value: '', type: 'textarea', name: 'alamat', placeholder: 'Alamat Rumah', disabled: true},
          ],
        },
      ],
      kehormatan:[
        {
          value: '',
          placeholder: 'Tanda Kehormatan',
        },
      ],
    };
  },
  async created() {
    const id = this.$route.params.id;
    this.fetchPerson(id);
  },
};