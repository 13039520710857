<template>
    <div class="post d-flex flex-column-fluid" id="kt_post">
		<!--begin::Container-->
		<div id="kt_content_container" class="container-xxl">
			<!--begin::Form-->
			<form class="form d-flex flex-column flex-lg-row" @submit="submitForm">
				<!--begin::Main column-->
				<div class="d-flex flex-column flex-lg-row-fluid gap-7 gap-lg-12">
					<!--begin::Order details-->
					<div class="card card-flush py-4">
						<div class="card-header card-header-stretch" v-if="cardType == 'KTAK'">
							<h3 class="card-title"></h3>
							<div class="card-toolbar">
								<ul class="nav nav-tabs nav-line-tabs nav-stretch fs-6 border-0" role="tablist">
									<li class="nav-item" role="presentation">
										<a class="nav-link active" data-bs-toggle="tab" href="#kt_tab_pane_1" aria-selected="true" role="tab">Isi Data</a>
									</li>
									<li class="nav-item" role="presentation">
										<a class="nav-link" data-bs-toggle="tab" href="#kt_tab_pane_2" aria-selected="false" role="tab" tabindex="-1">Data Suami/Istri</a>
									</li>
								</ul>
							</div>
						</div>
						<div class="card-body pt-0">
							<div class="tab-content" id="myTabContent">
								<div class="tab-pane fade active show" id="kt_tab_pane_1" role="tabpanel">
									<div>
										<FormGroup :title="'Biodata'" :forms="biodata" ></FormGroup>
										<hr>
										<FormGroup :title="'Sinyalemen'" :forms="sinyalemen" ></FormGroup>
										<div >
											<hr>
											<FormDynamic :title="'Tanda Kehormatan'" :forms="kehormatan" @FormDynamic="forms = $kehormatan" :disabled="true"></FormDynamic>
										</div>
										<hr>
									</div>
									<div class="d-flex justify-content-end">
										<router-link :to="{ name: 'submission-kta' }" class="btn btn-light me-5">Kembali</router-link>
										<button type="submit" id="kt_ecommerce_edit_order_submit" class="btn btn-success">Kirim Pengajuan</button>
									</div>
								</div>
								
								<div class="tab-pane fade" id="kt_tab_pane_2" role="tabpanel">
									<FormGroup :title="'Biodata'" :forms="template.biodata" ></FormGroup>
									<hr>
									<FormGroup :title="'Sinyalemen'" :forms="template.sinyalemen" ></FormGroup>
									<div v-if="cardType=='KTP'" >
										<hr>
										<FormDynamic :title="'Tanda Kehormatan'" :forms="template.kehormatan" @FormDynamic="forms = $kehormatan"></FormDynamic>
									</div>
									<hr>
									<div class="d-flex justify-content-end">
										<router-link :to="{ name: 'submission-kta' }" class="btn btn-light me-5">Kembali</router-link>
									</div>
								</div>
							</div>
						</div>
					</div>
					<!--end::Order details-->
				</div>
				<!--end::Main column-->
			</form>
			<!--end::Form-->
		</div>
		<!--end::Container-->
	</div>
</template>
<script>
import Script from "@/scripts/kta/submission-detail";

export default {
  mixins: [Script],
}
</script>